<template>
  <div>
    <notifications group="notification" position="top right" />
    <div class="card card-custom gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-center">
          <span class="card-label font-weight-bolder text-dark">
            {{ $t('PAGES.DEVICES') }}
          </span>
        </h3>
        <div class="card-toolbar">
          <button @click="getDevices" class="btn btn-light-primary mr-2">
            <span class="svg-icon svg-icon-md mr-0">
              <inline-svg src="media/svg/icons/Media/Repeat.svg" />
            </span>
          </button>
          <router-link
            v-if="
              currentUserRole === 'ROLE_ROOT' ||
              currentUserRole === 'ROLE_SUPER_ADMIN' ||
              currentUserRole === 'ROLE_ADMIN'
            "
            to="/devices/new"
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md">
              <inline-svg src="media/svg/icons/Electric/Outlet.svg" /> </span
            >{{ $t('GENERAL.ADD') }}
          </router-link>
        </div>
      </div>

      <div class="card-body py-0">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-xl mb-3 mb-xl-0">
            <div class="input-group">
              <input
                type="text"
                v-model="deviceName"
                class="form-control form-control-solid form-control-lg"
                :placeholder="$t('GENERAL.ENTER_DEVICE_NAME')"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-xl mb-3 mb-xl-0">
            <div class="input-group">
              <input
                type="text"
                v-model="deviceLocation"
                class="form-control form-control-solid form-control-lg"
                placeholder="Enter device location"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-xl mb-3 mb-xl-0">
            <div class="input-group">
              <input
                type="text"
                v-model="deviceTags"
                class="form-control form-control-solid form-control-lg"
                placeholder="Enter device tags"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-xl mb-3 mb-xl-0">
            <div class="input-group">
              <input
                type="text"
                v-model="searchImei"
                class="form-control form-control-solid form-control-lg"
                placeholder="Enter device IMEI"
              />
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-xl mb-3 mb-xl-0">
            <button
              @click="searchDevice"
              class="btn btn-light-primary btn-block"
              style="height: 44px"
            >
              {{ $t('GENERAL.SEARCH') }}
            </button>
          </div>
        </div>
        <div class="separator separator-dashed my-5"></div>
        <div class="row">
          <div class="col-md-12">
            <div v-if="isLoading" class="col-12 text-center pb-5">
              <div class="text-center my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-4">{{ $t('GENERAL.LOADING') }}</strong>
              </div>
            </div>
            <div
              v-else-if="!isLoading && devices.length > 0"
              class="table-responsive"
            >
              <table
                class="table table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                  <tr class="text-uppercase">
                    <th style="min-width: 120px" class="pl-6">
                      <span
                        @click="searchDevice('nameOrder')"
                        class="cursor-pointer"
                      >
                        <span
                          class="pt-2"
                          :class="{ 'text-primary': nameOrder.isActive }"
                        >
                          Name</span
                        >
                        <span
                          class="svg-icon svg-icon-sm pl-2"
                          :class="{
                            'svg-icon-primary': nameOrder.isActive,
                          }"
                        >
                          <inline-svg
                            v-if="nameOrder.query === 'asc'"
                            src="media/svg/icons/Navigation/Down-2.svg"
                          />
                          <inline-svg
                            v-else
                            src="media/svg/icons/Navigation/Up-2.svg"
                          />
                        </span>
                      </span>
                      <span v-if="currentUserCompanyOib === '05959244936'">
                        <span>&</span>
                        <span
                          v-if="currentUserCompanyModule[0] === 'gaming'"
                          @click="searchDevice('locationOrder')"
                          class="cursor-pointer"
                        >
                          <span
                            class="pt-2"
                            :class="{ 'text-primary': locationOrder.isActive }"
                          >
                            {{ $t('GENERAL.LOCATION') }}</span
                          >
                          <span
                            class="svg-icon svg-icon-sm pl-2"
                            :class="{
                              'svg-icon-primary': locationOrder.isActive,
                            }"
                          >
                            <inline-svg
                              v-if="locationOrder.query === 'asc'"
                              src="media/svg/icons/Navigation/Down-2.svg"
                            />
                            <inline-svg
                              v-else
                              src="media/svg/icons/Navigation/Up-2.svg"
                            />
                          </span>
                        </span>
                        <span
                          v-else
                          @click="searchDevice('locationOrder')"
                          class="cursor-pointer"
                        >
                          <span
                            class="pt-2"
                            :class="{ 'text-primary': locationOrder.isActive }"
                          >
                            {{ $t('GENERAL.LOCATION') }}</span
                          >
                          <span
                            class="svg-icon svg-icon-sm pl-2"
                            :class="{
                              'svg-icon-primary': locationOrder.isActive,
                            }"
                          >
                            <inline-svg
                              v-if="locationOrder.query === 'asc'"
                              src="media/svg/icons/Navigation/Down-2.svg"
                            />
                            <inline-svg
                              v-else
                              src="media/svg/icons/Navigation/Up-2.svg"
                            />
                          </span>
                        </span>
                      </span>
                    </th>
                    <th style="max-width: 100px">Tags</th>
                    <th style="min-width: 120px">Imei</th>
                    <th
                      v-if="
                        currentUserRole === 'ROLE_ROOT' ||
                        currentUserRole === 'ROLE_SUPER_ADMIN' ||
                        currentUserRole === 'ROLE_ADMIN' ||
                        currentUserRole === 'ROLE_WORKER' ||
                        currentUserRole === 'ROLE_JUNIOR'
                      "
                      class="text-right"
                      style="min-width: 100px"
                    >
                      Token
                    </th>
                    <th
                      v-if="
                        currentUserRole === 'ROLE_ROOT' ||
                        currentUserRole === 'ROLE_SUPER_ADMIN' ||
                        currentUserRole === 'ROLE_ADMIN'
                      "
                      class="text-right"
                      style="min-width: 100px"
                    >
                      Saldo
                    </th>
                    <th class="text-right" style="min-width: 100px">
                      <span
                        @click="searchDevice('statusOrder')"
                        class="cursor-pointer"
                      >
                        <span
                          class="pt-2"
                          :class="{ 'text-primary': statusOrder.isActive }"
                        >
                          Active</span
                        >
                        <span
                          class="svg-icon svg-icon-sm pl-2"
                          :class="{
                            'svg-icon-primary': statusOrder.isActive,
                          }"
                        >
                          <inline-svg
                            v-if="statusOrder.query === 'asc'"
                            src="media/svg/icons/Navigation/Down-2.svg"
                          />
                          <inline-svg
                            v-else
                            src="media/svg/icons/Navigation/Up-2.svg"
                          />
                        </span>
                      </span>
                    </th>
                    <th
                      v-if="
                        currentUserRole === 'ROLE_ROOT' ||
                        currentUserRole === 'ROLE_SUPER_ADMIN' ||
                        currentUserRole === 'ROLE_ADMIN'
                      "
                      class="text-right"
                      style="min-width: 120px"
                    >
                      <span
                        @click="searchDevice('serviceModeOrder')"
                        class="cursor-pointer"
                      >
                        <span
                          class="pt-2"
                          :class="{ 'text-primary': serviceModeOrder.isActive }"
                        >
                          Service</span
                        >
                        <span
                          class="svg-icon svg-icon-sm pl-2"
                          :class="{
                            'svg-icon-primary': serviceModeOrder.isActive,
                          }"
                        >
                          <inline-svg
                            v-if="serviceModeOrder.query === 'asc'"
                            src="media/svg/icons/Navigation/Down-2.svg"
                          />
                          <inline-svg
                            v-else
                            src="media/svg/icons/Navigation/Up-2.svg"
                          />
                        </span>
                      </span>
                    </th>
                    <th
                      v-if="
                        currentUserRole === 'ROLE_JUNIOR' ||
                        currentUserRole === 'ROLE_SUPER_ADMIN' ||
                        currentUserRole === 'ROLE_ADMIN'
                      "
                      class="text-right"
                      style="min-width: 120px; white-space: nowrap"
                    >
                      {{ $t('DEVICES.LAST_EMPTY') }}
                    </th>
                    <th
                      class="text-right pr-6"
                      style="min-width: 160px; width: 270px; text-align: right"
                    >
                      {{ $t('GENERAL.ACTION') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(device, key) in devices" :key="device.id">
                    <td class="pl-6" :class="{ 'border-top-0': key === 0 }">
                      <router-link
                        v-if="
                          currentUserRole === 'ROLE_ROOT' ||
                          currentUserRole === 'ROLE_SUPER_ADMIN' ||
                          currentUserRole === 'ROLE_ADMIN'
                        "
                        :to="'/devices/edit/' + device.id"
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >{{ device.name }}
                      </router-link>
                      <span
                        v-else
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >{{ device.name }}</span
                      >
                      <div v-if="currentUserCompanyModule[0] === 'gaming'">
                        <div v-if="device.location.lessor">
                          <span class="font-weight-bolder"
                            >{{ $t('GENERAL.LOCATION') }}:
                          </span>
                          <a
                            class="text-muted font-weight-bold text-hover-primary"
                            href="#"
                            >{{ device.location.title }}</a
                          >
                        </div>
                      </div>
                      <div v-else>
                        <div v-if="device.location">
                          <span class="font-weight-bolder">Location: </span>
                          <a
                            class="text-muted font-weight-bold text-hover-primary"
                            href="#"
                            >{{ device.location.title }}</a
                          >
                        </div>
                      </div>
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span
                        v-for="tag in device.tags"
                        :key="tag.id"
                        class="label label-lg label-light-primary label-inline mr-2"
                        >{{ tag.name }}</span
                      >
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span
                        v-if="device.logger"
                        class="text-muted font-weight-500"
                        >{{ device.logger.imei }}</span
                      >
                      <span v-else class="text-muted font-weight-500"
                        >No logger</span
                      >
                    </td>
                    <td
                      v-if="
                        currentUserRole === 'ROLE_ROOT' ||
                        currentUserRole === 'ROLE_SUPER_ADMIN' ||
                        currentUserRole === 'ROLE_ADMIN' ||
                        currentUserRole === 'ROLE_WORKER' ||
                        currentUserRole === 'ROLE_JUNIOR'
                      "
                      class="text-right"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <span
                        v-if="device.token != 0"
                        class="text-dark flex-root font-weight-bold"
                        >{{ parseFloat(device.token) }}</span
                      >
                      <span v-else class="text-dark flex-root font-weight-bold"
                        >0</span
                      >
                    </td>
                    <td
                      v-if="
                        currentUserRole === 'ROLE_ROOT' ||
                        currentUserRole === 'ROLE_SUPER_ADMIN' ||
                        currentUserRole === 'ROLE_ADMIN'
                      "
                      class="text-right"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <span
                        v-if="device.saldo != 0"
                        class="text-dark flex-root font-weight-bold"
                        >{{ parseFloat(device.saldo) | currencyFormat }}</span
                      >
                      <span v-else class="text-dark flex-root font-weight-bold"
                        >0,00 €</span
                      >
                    </td>
                    <td
                      class="text-right"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <span
                        v-if="device.isActive"
                        class="label label-lg label-light-success label-inline"
                        >{{ $t('GENERAL.YES') }}</span
                      >
                      <span
                        v-else
                        class="label label-lg label-light-danger label-inline"
                        >{{ $t('GENERAL.NO') }}</span
                      >
                    </td>
                    <td
                      v-if="
                        currentUserRole === 'ROLE_ROOT' ||
                        currentUserRole === 'ROLE_SUPER_ADMIN' ||
                        currentUserRole === 'ROLE_ADMIN'
                      "
                      class="text-right"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <VtSwitch
                        @checkedEvent="
                          updateServiceMode(device.serviceMode, device.id, key)
                        "
                        :is-checked="device.serviceMode"
                        classes="switch-sm switch-icon switch-outline switch-danger float-right"
                        name="villaIsFeatured"
                      />
                    </td>
                    <td
                      v-if="
                        currentUserRole === 'ROLE_JUNIOR' ||
                        currentUserRole === 'ROLE_SUPER_ADMIN' ||
                        currentUserRole === 'ROLE_ADMIN'
                      "
                      class="text-center"
                    >
                      {{
                        device.eventMeta.lastEmpty
                          ? formatDate(device.eventMeta.lastEmpty)
                          : '-'
                      }}
                    </td>
                    <td
                      class="text-right pr-6"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <button
                        @click="toggleDeviceMaintenanceModal(device)"
                        class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                        v-b-tooltip.hover.top="
                          $t('DEVICES.JUNIOR_DEVICE_QUESTIONNAIRE')
                        "
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/General/Settings-2.svg"
                          />
                        </span>
                      </button>
                      <a
                        @click="
                          generateDeviceQrCodeUrl(
                            device.logger.imei,
                            device.isuNumber,
                            device.businessSpaceLabel
                          )
                        "
                        href="#"
                        class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                        v-b-modal.deviceQrCode
                        v-b-tooltip.hover.top="$t('DEVICES.SHOW_TAX_STICKER')"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/Design/Position.svg"
                          />
                        </span>
                      </a>
                      <button
                        @click="generateDeviceMaintenanceQrCodeUrl(device)"
                        class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                        v-b-tooltip.hover.top="$t('DEVICES.SHOW_TAX_STICKER')"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/Electric/Outlet.svg"
                          />
                        </span>
                      </button>
                      <button
                        @click.prevent="emptyDevice(device.logger.imei)"
                        class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                        v-b-tooltip.hover.top="$t('DEVICES.EMPTY_DEVICE')"
                      >
                        <span
                          class="svg-icon svg-icon-md"
                          :class="{
                            'svg-icon-danger': parseFloat(device.saldo) != 0,
                          }"
                        >
                          <inline-svg src="media/svg/icons/Media/Repeat.svg" />
                        </span>
                      </button>
                      <router-link
                        v-if="
                          currentUserRole === 'ROLE_ROOT' ||
                          currentUserRole === 'ROLE_SUPER_ADMIN' ||
                          currentUserRole === 'ROLE_ADMIN'
                        "
                        :to="'/devices/edit/' + device.id"
                        class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                        v-b-tooltip.hover.top="$t('DEVICES.EDIT_DEVICE')"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/General/Settings-1.svg"
                          />
                        </span>
                      </router-link>
                      <a
                        v-if="
                          currentUserRole === 'ROLE_ROOT' ||
                          currentUserRole === 'ROLE_SUPER_ADMIN' ||
                          currentUserRole === 'ROLE_ADMIN'
                        "
                        @click="deleteEntity(device.id)"
                        href="#"
                        class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                        v-b-tooltip.hover.top="$t('DEVICES.DELETE_DEVICE')"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg src="media/svg/icons/General/Trash.svg" />
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div
                class="mt-10 overflow-auto d-flex justify-content-center align-items-center"
              >
                <b-pagination-nav
                  :link-gen="linkGen"
                  :number-of-pages="pagination.totalPages"
                  use-router
                  size="lg"
                ></b-pagination-nav>
              </div>
            </div>
            <div
              v-else-if="!isLoading && devices.length === 0"
              class="row"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                height: 100%;
              "
            >
              <div class="col-12 pt-4 pb-6 text-center px-6 pl-10">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    height: 100%;
                  "
                >
                  <p class="font-weight-bold" style="font-size: 24px">
                    No devices.
                  </p>
                  <p style="font-size: 16px">There are currently no devices.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <b-modal
      id="deviceQrCode"
      body-class="text-center"
      size="lg"
      hide-header
      hide-footer
    >
      <div>
        <b-button
          class="btn btn-light-primary mb-3"
          block
          @click="$bvModal.hide('deviceQrCode')"
          >Close</b-button
        >
        <div ref="printMe" class="qr-code-modal">
          <img
            class="qr-code-modal__image"
            :src="qrCodeImageTemplate"
            alt="QR Code"
          />
          <VueQrcode
            :value="qrCodeUrl"
            :options="{ width: 100 }"
            class="qr-code-modal__code"
          ></VueQrcode>
          <div class="qr-code-modal__isu-number">
            <span
              ><strong> {{ isuNumber }}</strong></span
            >
          </div>
          <div class="qr-code-modal__opp">
            <span
              ><strong> {{ businessSpaceLabel }}</strong></span
            >
          </div>
        </div>
        <button @click="print" class="btn btn-primary my-5">
          Generiraj print format
        </button>
        <img :src="output" />
      </div>
    </b-modal>
    <div class="row">
      <div class="col-12">
        <b-modal
          id="device_maintenance_modal"
          size="xl"
          hide-header
          hide-footer
        >
          <div>
            <b-button
              class="btn btn-light-primary mb-3"
              block
              @click="$bvModal.hide('device_maintenance_modal')"
              >Close</b-button
            >
            <MaintenanceWizard
              v-if="isMaintenanceWizardReady"
              :selected-device-id="selectedDeviceId"
              :selected-device="selectedDevice"
              :is-single="false"
              :onComplete="getDevices"
            ></MaintenanceWizard>
          </div>
        </b-modal>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-modal
          id="device_maintenance_qr_code_modal"
          size="sx"
          hide-header
          hide-footer
          centered
        >
          <div ref="printMe2" style="position: relative; text-align: center">
            <b-button
              class="btn btn-light-primary mb-3"
              block
              @click="$bvModal.hide('device_maintenance_qr_code_modal')"
              >Close</b-button
            >
            <VueQrcode
              :value="qreMaintenanceCodeUrl"
              :options="{ width: 300 }"
            ></VueQrcode>
            <h4>
              <strong>{{ qreMaintenanceCodeTitle }}</strong>
            </h4>
            <h5>{{ qreMaintenanceCodeId }}</h5>
            <img :src="output2" />
          </div>
          <div class="text-center">
            <button @click="print2" class="btn btn-primary my-5">
              Generiraj print format
            </button>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import VtSwitch from '@/view/content/components/switch/VtSwitch'
import MaintenanceWizard from '@/view/pages/devices/_components/MaintenanceWizard'
import { mapGetters } from 'vuex'
import { parseISO } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'

export default {
  name: 'DevicesList',
  components: {
    VueQrcode,
    VtSwitch,
    MaintenanceWizard,
  },
  data() {
    return {
      devices: [],
      isDevicesLoaded: false,
      totalItems: 0,
      deviceName: '',
      deviceLocation: '',
      deviceTags: '',
      searchImei: '',
      searchQuery: '',
      qrCodeUrl: '',
      qreMaintenanceCodeUrl: '',
      qreMaintenanceCodeTitle: '',
      qreMaintenanceCodeId: '',
      qrCodeImageTemplate: 'media/qr-code/qr-code-template.png',
      isuNumber: null,
      businessSpaceLabel: null,
      endpointUrlQuery: '',
      output: null,
      output2: null,
      nameOrder: {
        query: 'asc',
        isActive: false,
        name: 'name',
      },
      locationOrder: {
        query: 'asc',
        isActive: false,
        name: 'location.title',
      },
      statusOrder: {
        query: 'asc',
        isActive: false,
        name: 'status',
      },
      serviceModeOrder: {
        query: 'asc',
        isActive: false,
        name: 'serviceMode',
      },
      selectedDeviceId: null,
      selectedDevice: null,
      isMaintenanceWizardReady: false,
      pagination: {
        totalPages: 0,
        currentPage: 1,
        itemsPerPage: 30,
      },
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters([
      'currentUserRole',
      'currentUserCompany',
      'currentUserPersonalInfo',
      'currentUserCompanyModule',
      'currentUserCompanyOib',
      'currentUserCompanyId',
    ]),
    page() {
      return this.$route.query.page ?? 1
    },
  },
  watch: {
    page() {
      this.searchDevice()
    },
  },
  mounted() {
    this.initDevices()
  },
  methods: {
    initDevices() {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Devices', route: '' }])
      this.getDevices()
    },
    resetMaintenanceWizard() {
      this.isMaintenanceWizardReady = false
      this.getDevices()
    },
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    getDevices() {
      this.pagination.currentPage = this.$route.query.page || 1
      this.isLoading = true

      if (
        this.currentUserRole === 'ROLE_ROOT' ||
        this.currentUserRole === 'ROLE_SUPER_ADMIN'
      ) {
        ApiService.get(
          'devices',
          `?itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}`
        )
          .then(({ data }) => {
            this.devices = [...data['hydra:member']]
            this.pagination.totalPages = Math.ceil(
              data['hydra:totalItems'] / this.pagination.itemsPerPage
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      }

      if (
        this.currentUserRole === 'ROLE_ADMIN' ||
        this.currentUserRole === 'ROLE_WORKER' ||
        this.currentUserRole === 'ROLE_JUNIOR'
      ) {
        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}&itemsPerPage=${this.pagination.itemsPerPage}&page=${
            this.pagination.currentPage
          }&select=flags,timestamps,related_ids,other`
        )
          .then(({ data }) => {
            this.totalItems = data['hydra:member'].length
            this.devices = [...data['hydra:member']]
            this.pagination.totalPages = Math.ceil(
              data['hydra:totalItems'] / this.pagination.itemsPerPage
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      } else if (this.currentUserRole === 'ROLE_WATCHER') {
        ApiService.get(
          'users',
          `?email=${this.currentUserPersonalInfo.email}`
        ).then(({ data }) => {
          const userLocations = data['hydra:member'][0]['locations']
          ApiService.get(
            'devices',
            `?company=${this.currentUserCompany.replace(
              '/api/v1/companies/',
              ''
            )}&location.id=${
              userLocations[0].id
            }&pagination=true&itemsPerPage=${
              this.pagination.itemsPerPage
            }&page=${
              this.pagination.currentPage
            }&select=flags,timestamps,related_ids,other`
          )
            .then(({ data }) => {
              this.totalItems = data['hydra:member'].length
              this.devices = [...data['hydra:member']]
              this.pagination.totalPages = Math.ceil(
                data['hydra:totalItems'] / this.pagination.itemsPerPage
              )
            })
            .finally(() => {
              this.isLoading = false
            })
        })
      }
    },
    generateDeviceQrCodeUrl(imei, isuNumber, businessSpaceLabel) {
      this.qrCodeUrl = `${process.env.VUE_APP_BILLS_API_URL}/bill/` + imei
      this.isuNumber = isuNumber
      this.businessSpaceLabel = businessSpaceLabel
    },
    generateDeviceMaintenanceQrCodeUrl(device) {
      this.output2 = null
      this.qreMaintenanceCodeUrl = null
      this.qreMaintenanceCodeTitle = null
      this.qreMaintenanceCodeId = null
      this.qreMaintenanceCodeUrl =
        window.location.origin + '/#/devices/maintenance/' + device.id
      this.qreMaintenanceCodeTitle = device.name
      this.qreMaintenanceCodeId = device.id
      this.$root.$emit('bv::show::modal', 'device_maintenance_qr_code_modal')
    },
    deleteEntity(id) {
      let confirmation = confirm('Želite izbrisati ovaj uređaj?')
      if (confirmation === false) return

      this.isLoading = true
      ApiService.delete(`devices/${id}`)
        .then(() => {
          this.searchDevice()
          // ApiService.get('devices', `?itemsPerPage=30&page=${this.pagination.currentPage}`).then((response) => {
          //   this.devices = [...response.data['hydra:member']]
          // })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    updateServiceMode(checked, entityId, key) {
      let confirmation = confirm(
        'Želite aktivirati servisni mod za ovaj uređaj?'
      )
      if (confirmation === false) return

      this.devices[key].serviceMode = !checked

      ApiService.put('devices', entityId, {
        serviceMode: this.devices[key].serviceMode,
      }).then(() => {
        this.$notify({
          group: 'notification',
          type: 'success',
          title: 'Uređaj uspješno izmjenjen.',
        })

        // Axios.get('https://json.geoiplookup.io/').then(({ data }) => {
        //   ApiService.post('device_service_mode_histories', {
        //     date: new Date(),
        //     user: this.currentUserPersonalInfo.email,
        //     imei: this.devices[key].logger.imei,
        //     device: this.devices[key]['@id'],
        //     meta: {
        //       serviceModeStatus: this.devices[key].serviceMode,
        //       appCodeName: navigator.appCodeName,
        //       appName: navigator.appName,
        //       appVersion: navigator.appVersion,
        //       cookieEnabled: navigator.cookieEnabled,
        //       platform: navigator.platform,
        //       userAgent: navigator.userAgent,
        //       asn: data.asn,
        //       asnNumber: data.asn_number,
        //       asnOrg: data.asn_org,
        //       city: data.city,
        //       connectionType: data.connection_type,
        //       continentCode: data.continent_code,
        //       continentName: data.continent_name,
        //       countryCode: data.country_code,
        //       countryName: data.country_name,
        //       district: data.district,
        //       hostname: data.hostname,
        //       ip: data.ip,
        //       isp: data.isp,
        //       latitude: data.latitude,
        //       longitude: data.longitude,
        //       region: data.region
        //     }
        //   }).then(() => {})
        // })

        ApiService.post('device_service_mode_histories', {
          date: new Date(),
          user: this.currentUserPersonalInfo.email,
          imei: this.devices[key].logger.imei,
          device: this.devices[key]['@id'],
          meta: {
            serviceModeStatus: this.devices[key].serviceMode,
            appCodeName: navigator.appCodeName,
            appName: navigator.appName,
            appVersion: navigator.appVersion,
            cookieEnabled: navigator.cookieEnabled,
            platform: navigator.platform,
            userAgent: navigator.userAgent,
          },
        }).then(() => {})
      })
    },
    searchDevice(order) {
      this.searchQuery = ''

      if (typeof order === 'string' && order) {
        this[order].isActive = true
        if (this[order].query === 'asc') this[order].query = 'desc'
        else this[order].query = 'asc'
      }

      this.pagination.currentPage = this.$route.query.page || 1

      if (this.nameOrder.isActive) {
        this.searchQuery += `&order[${this.nameOrder.name}]=${this.nameOrder.query}`
      }
      if (this.locationOrder.isActive) {
        this.searchQuery += `&order[${this.locationOrder.name}]=${this.locationOrder.query}`
      }
      if (this.statusOrder.isActive) {
        this.searchQuery += `&order[${this.statusOrder.name}]=${this.statusOrder.query}`
      }
      if (this.serviceModeOrder.isActive) {
        this.searchQuery += `&order[${this.serviceModeOrder.name}]=${this.serviceModeOrder.query}`
      }
      if (this.deviceName) this.searchQuery += `&name=${this.deviceName}`
      if (this.deviceLocation)
        this.searchQuery += `&location.title=${this.deviceLocation}`
      if (this.deviceTags) this.searchQuery += `&tags.name[]=${this.deviceTags}`
      if (this.searchImei) this.searchQuery += `&logger.imei=${this.searchImei}`

      this.isLoading = true
      this.devices = []

      if (
        this.currentUserRole === 'ROLE_ROOT' ||
        this.currentUserRole === 'ROLE_SUPER_ADMIN'
      ) {
        ApiService.get(
          'devices',
          `?itemsPerPage=${this.pagination.itemsPerPage}&page=${this.pagination.currentPage}${this.searchQuery}`
        )
          .then(({ data }) => {
            this.devices = [...data['hydra:member']]
            this.pagination.totalPages = Math.ceil(
              data['hydra:totalItems'] / this.pagination.itemsPerPage
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      } else if (this.currentUserRole === 'ROLE_WATCHER') {
        ApiService.get(
          'users',
          `?email=${this.currentUserPersonalInfo.email}`
        ).then(({ data }) => {
          const userLocations = data['hydra:member'][0]['locations']
          ApiService.get(
            'devices',
            `?company=${this.currentUserCompany.replace(
              '/api/v1/companies/',
              ''
            )}${this.searchQuery}&location.id=${
              userLocations[0].id
            }&pagination=true&itemsPerPage=${
              this.pagination.itemsPerPage
            }&page=${
              this.pagination.currentPage
            }&select=flags,timestamps,related_ids,other`
          )
            .then(({ data }) => {
              this.devices = [...data['hydra:member']]
              this.pagination.totalPages = Math.ceil(
                data['hydra:totalItems'] / this.pagination.itemsPerPage
              )
            })
            .finally(() => {
              this.isLoading = false
            })
        })
      } else {
        ApiService.get(
          'devices',
          `?company=${this.currentUserCompany.replace(
            '/api/v1/companies/',
            ''
          )}${this.searchQuery}&itemsPerPage=${
            this.pagination.itemsPerPage
          }&page=${this.pagination.currentPage}`
        )
          .then(({ data }) => {
            this.devices = [...data['hydra:member']]
            this.pagination.totalPages = Math.ceil(
              data['hydra:totalItems'] / this.pagination.itemsPerPage
            )
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
    async print() {
      const el = this.$refs.printMe
      const options = {
        type: 'dataURL',
      }
      this.output = await this.$html2canvas(el, options)
    },
    async print2() {
      const el = this.$refs.printMe2
      const options = {
        type: 'dataURL',
      }
      this.output2 = await this.$html2canvas(el, options)
    },
    toggleDeviceMaintenanceModal(device) {
      this.selectedDeviceId = device['@id']
      this.selectedDevice = device
      this.isMaintenanceWizardReady = true

      this.$root.$emit('bv::show::modal', 'device_maintenance_modal')
    },
    emptyDevice(imei) {
      let confirmation = confirm('You want to empty this device?')
      if (confirmation === false) return
      ApiService.post(`/send-empty-event-email/${imei}`)

      ApiService.post('messages', {
        imei: imei,
        data: [{ type: 'empty' }],
      }).then(() => {
        ApiService.post(`/send-empty-event-email/${imei}`)
      })
    },
    formatDate(dateString) {
      const date = parseISO(dateString)
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      return formatInTimeZone(date, userTimeZone, 'dd.MM.yyyy @ HH:mm')
    },
  },
}
</script>

<style lang="scss">
.modal-body {
  img {
    width: 100%;
  }
}
.qr-code-modal {
  position: relative;

  &__image {
    position: relative;
    width: 100%;
  }
  &__code {
    position: absolute;
    bottom: 7px;
    right: 22px;
  }
  &__isu-number {
    position: absolute;
    bottom: 171px;
    left: 80px;
    font-size: 22px;
  }
  &__opp {
    position: absolute;
    bottom: 135px;
    left: 426px;
    font-size: 22px;
  }
}
</style>
